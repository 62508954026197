import * as React from 'react';
import { styled, css } from '@mui/material/styles';

import {
  useMediaQuery,
  Theme as MUITheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  NoSsr,
} from '@mui/material';
import { IconChevronDown, List } from '@maiia/design-system';
import { informationsAndArticlesFooter } from './footerMenus';
import { useTranslation } from '@/src/i18n';

/* JS styling */

const FooterAccordion = styled(Accordion)<{ dynamic?: boolean }>(
  ({ theme, dynamic = false }) => css`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid
      ${dynamic
        ? theme.palette.background.lightGrey
        : theme.palette.common.white};

    &:last-of-type {
      border-radius: 0;
    }

    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;

      .MuiAccordionSummary-content {
        margin-top: ${theme.spacing(3)};
        margin-bottom: ${theme.spacing(3)};

        h2 {
          margin-bottom: 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: auto;
          height: ${theme.spacing(3)};
          fill: ${dynamic
            ? theme.palette.infoBlue.light
            : theme.palette.common.white};
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 0 0 ${theme.spacing(3)} 0;

      ul {
        li {
          color: ${dynamic
            ? theme.palette.infoBlue.light
            : theme.palette.common.white};
        }
      }
    }
  `,
);

/* Component */

const MenuInformationsAndArticles = ({ dynamic = false }) => {
  const { t } = useTranslation();
  const footerMenusTranslated = informationsAndArticlesFooter(t);
  const isMobile = useMediaQuery<MUITheme>(theme =>
    theme.breakpoints.down('sm'),
  );

  const accordionTitle = (
    <Typography variant="h3" component="h2">
      {t('footer_informations_and_articles')}
    </Typography>
  );
  const accordionContent = (
    <List listItems={footerMenusTranslated} className="with-disc" />
  );

  return (
    <NoSsr>
      {isMobile ? (
        <FooterAccordion dynamic={dynamic}>
          <AccordionSummary expandIcon={<IconChevronDown />}>
            {accordionTitle}
          </AccordionSummary>
          <AccordionDetails>{accordionContent}</AccordionDetails>
        </FooterAccordion>
      ) : (
        <>
          {accordionTitle}
          {accordionContent}
        </>
      )}
    </NoSsr>
  );
};

export default MenuInformationsAndArticles;
