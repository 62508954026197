import React, { useCallback } from 'react';
import { styled, css } from '@mui/material/styles';

import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMediaQuery,
  Theme as MUITheme,
  Button,
  IconButton,
  NoSsr,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { devices, IconCheckRoundFilled, IconCross } from '@maiia/design-system';
import { asyncActions } from '@docavenue/core';
import { useCurrentUser } from '@docavenue/components';
import { getCardFullName } from '@/components/utils';
import { appointmentsActions } from '@/src/actions';
import { removeHomeBanner } from '@/src/actions/ui';
import { useTranslation } from '@/src/i18n';
import { enqueueSnackbar } from '@/src/actions/snackbar';

type MovedRDVSuccessBannerProps = {
  appointmentId: string;
};

const homeBannerSelector = state => state.ui?.banner;

/* JS styling */

const HomeBannerWrapper = styled('div')(
  ({ theme }) => css`
    position: fixed;
    width: 100%;
    height: ${theme.spacing(6)};
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)}
      ${theme.spacing(1)};
    background-color: ${theme.palette.background.primary};
    z-index: 1300;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    & + .header {
      top: ${theme.spacing(6)};

      & + .page {
        margin-top: ${theme.spacing(12)};
      }
    }
  `,
);

const BannerInformation = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center; // Center elements vertically

    @media ${devices.mobile} {
      width: 50%;
      font-size: ${theme.typography.body2.fontSize};
      line-height: 1.2;
    }
  `,
);

const BannerIcon = styled('div')(
  ({ theme }) => css`
    margin-right: ${theme.spacing(1)};

    @media ${devices.mobile} {
      display: none;
    }

    svg {
      width: auto;
      height: ${theme.spacing(4)};
      color: ${theme.palette.success.main};
    }
  `,
);

const BannerCta = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center; // Center elements vertically

    .MuiButton-contained {
      @media ${devices.mobile} {
        font-size: ${theme.typography.body2.fontSize};
        padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
      }
      @media ${devices['tablet+desktop']} {
        margin-right: ${theme.spacing(1)};
      }
      @media ${devices.desktop} {
        min-width: ${theme.spacing(25)};
      }
    }
  `,
);

/* Page */

const MovedRDVSuccessBanner = ({
  appointmentId,
}: MovedRDVSuccessBannerProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery<MUITheme>(theme =>
    theme.breakpoints.down('sm'),
  );
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const { data: appointment, isLoading, isError } = useQuery(
    ['appointments', appointmentId],
    () =>
      asyncActions(
        dispatch,
        appointmentsActions.getOne(appointmentId, {
          aggregateWith: 'cardHcd',
        }),
      ),
    {
      enabled: !!currentUser,
      onError: () => {
        dispatch(removeHomeBanner());
      },
    },
  );
  const onViewClick = useCallback(() => {
    dispatch(removeHomeBanner());
    dispatch(
      enqueueSnackbar({
        message: t(`rdv_waiting_proposal_accepted`),
        severity: 'success',
        duration: 5000,
        delay: 1500,
      }),
    );
  }, []);
  const onCloseClick = useCallback(() => dispatch(removeHomeBanner()), []);
  if (isLoading || isError) return null;
  const fullName = getCardFullName(t, appointment.cardHcd);

  return (
    <HomeBannerWrapper>
      <BannerInformation>
        <BannerIcon>
          <IconCheckRoundFilled />
        </BannerIcon>
        <NoSsr>
          <p>
            {/* Make sure that text will fit on maximum three lines on mobile (about 70 characters) */}
            {isMobile &&
            t('moved_appointment_success', { practicien: fullName }).length > 70
              ? `${t('moved_appointment_success', {
                  practicien: fullName,
                }).substring(0, 67)}...`
              : t('moved_appointment_success', { practicien: fullName })}

            {}
          </p>
        </NoSsr>
      </BannerInformation>
      <BannerCta>
        <div onClick={onViewClick}>
          <Link
            href={{
              pathname: '/appointment/[id]',
              query: { id: appointmentId },
            }}
            passHref
          >
            <Button variant="contained" color="secondary">
              {t('view_my_appointment')}
            </Button>
          </Link>
        </div>
        <div onClick={onCloseClick}>
          <IconButton>
            <IconCross />
          </IconButton>
        </div>
      </BannerCta>
    </HomeBannerWrapper>
  );
};

const HomeBanner = () => {
  const homeBannerState = useSelector(homeBannerSelector);
  if (!homeBannerState) return null;
  if (homeBannerState.bannerType === 'MOVED_APPOINMENT_SUCCESS')
    return <MovedRDVSuccessBanner {...homeBannerState.params} />;
  console.error('not supported bannerType', homeBannerState.bannerType);
  return null;
};

export default HomeBanner;
