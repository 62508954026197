/* eslint-disable import/prefer-default-export */
import axios, { AxiosInstance, AxiosHeaders } from 'axios';
import omitBy from 'lodash/omitBy';
import { EncryptionService, toJPEGBlob } from '@docavenue/core';
import config from '../config';

let apiClientInstance: AxiosInstance | null = null;

/**
 * Returns the instance created in setupApiClient(store)
 * In the futur, when we don't need store anymore, this will be a real singleton
 */
export function getApiClient({
  apiName,
}: {
  apiName: 'api-pro' | 'api-patient';
}) {
  if (process.env.NODE_ENV !== 'production' && apiName === 'api-pro') {
    console.warn(
      "You called getApiClient with 'api-pro' in pat-frontend, did you mean to do that?",
    );
  }
  if (!apiClientInstance) {
    throw new Error(
      'No api client initialized, make sure `setupApiClient` was called',
    );
  }
  return apiClientInstance;
}

type SetupApiClientType = {
  getToken: (url?: string) => string | undefined;
  getEncryptionInfos: (
    url?: string,
  ) =>
    | {
        aesFileEncryptionKey?: string;
        ivFileEncryptionKey?: string;
        encryption: boolean;
        publicKey?: string | null;
      }
    | undefined;
};

export const setupApiClient = ({
  getToken,
  getEncryptionInfos,
}: SetupApiClientType) => {
  function isEncryptionActive(
    url,
    encryptionService: EncryptionService | null,
  ) {
    const encryptionInfos = getEncryptionInfos(url);
    const scope = url?.split('/').filter(Boolean)[0] || ''; // url could start with leading slash (or not)
    return (
      encryptionInfos?.encryption &&
      encryptionService !== null &&
      (scope === 'auth' || scope === 'pat')
    );
  }

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'X-From': 'axios',
  };
  apiClientInstance = axios.create({
    baseURL:
      typeof window !== 'undefined'
        ? config.get('API_ENDPOINT')
        : config.get('SERVER_API_ENDPOINT'),
    headers,
  });

  let encryptionService: EncryptionService | null = null;
  if (typeof window !== 'undefined') {
    encryptionService = new EncryptionService(window.localStorage);
  }

  apiClientInstance.interceptors.request.use(async req => {
    const withEncryption = isEncryptionActive(req.url, encryptionService);
    const encryptionInfos = getEncryptionInfos(req.url);
    let iv; // initialization vector

    if (withEncryption && encryptionService) {
      const keyId = encryptionService.storage.getItem('keyId');
      if (!keyId) {
        // TODO: no need go to next step
        console.error('Missing keyId to provide to headers');
      }
      iv = await EncryptionService.generateIV();
      req.headers['Key-Id'] = keyId;
      req.headers.Iv = iv;
    }

    // retrieve token in store - for the moment, authentication / refresh token is not handled
    const token = getToken(req.url);
    if (!req.headers.Authorization && token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    if (withEncryption) {
      if (!req.headers.Authorization) {
        throw new Error('Missing token'); // todo allow encrypted request without `Authorization` headers ?
      }
      const aesKey = encryptionService?.getAESKey();

      const authorization = await EncryptionService.encrypt64(
        aesKey,
        iv,
        req.headers.Authorization as string,
      );
      req.headers.Authorization = authorization;

      if (req.headers.refresh) {
        req.headers.refresh = await EncryptionService.encrypt64(
          aesKey,
          iv,
          req.headers.refresh,
        );
      }

      req.responseType = 'blob';

      if (req.data) {
        if (
          typeof req.headers['Content-Type'] === 'string' &&
          req.headers['Content-Type'].toLowerCase() === 'multipart/form-data' &&
          typeof req.data === 'object' &&
          req.data !== null
        ) {
          // from `packages/core/src/rest/dataSimpleRest/fetchJson.ts`
          const promises: Promise<[string, any]>[] = Object.entries(
            req.data,
          ).map(([entryKey, entryValue]) => {
            if (entryValue instanceof File) {
              let blob = Promise.resolve(entryValue);
              if (entryValue?.type?.includes('image')) {
                // eslint-disable-next-line no-await-in-loop
                blob = toJPEGBlob(entryValue, 1920);
              }

              if (typeof encryptionInfos?.aesFileEncryptionKey !== 'string') {
                throw new Error('Missing aesFileEncryptionKey');
              }

              if (typeof encryptionInfos?.ivFileEncryptionKey !== 'string') {
                throw new Error('Missing ivFileEncryptionKey');
              }

              return new Promise((resolve, reject) =>
                blob
                  .then(b =>
                    EncryptionService.encryptFile(
                      encryptionInfos.aesFileEncryptionKey as string,
                      encryptionInfos.ivFileEncryptionKey as string,
                      b,
                    ),
                  )
                  .then(r => {
                    resolve([entryKey, r]);
                  })
                  .catch(reject),
              );
            }
            return Promise.resolve([entryKey, entryValue]);
          });

          const results = await Promise.all(promises);
          const encryptedFormData = new FormData();
          for (const [key, value] of results) {
            encryptedFormData.append(key, value);
          }
          req.data = encryptedFormData;
        } else {
          const encrypted = await EncryptionService.encrypt64(
            aesKey,
            iv,
            JSON.stringify(req.data),
          );
          req.data = {
            cipher: encrypted.toString(),
          };
        }
      }
    }

    // url encode query params
    if (req.params) {
      req.params = new URLSearchParams(
        omitBy(req.params, o => typeof o === 'undefined'),
      );
    }
    return req;
  });

  apiClientInstance.interceptors.response.use(async res => {
    const withEncryption = isEncryptionActive(
      res.config.url,
      encryptionService,
    );
    const encryptionInfos = getEncryptionInfos(res.config.url);
    const responseHeaders = res.headers as AxiosHeaders;

    const aesKey =
      withEncryption && encryptionService
        ? encryptionService.getAESKey()
        : null;

    /**
     * For the moment, redux-saga remains master for the authentication and refreshing token
     * Those infos are accessed via `getToken` and `getEncryptionInfos`
     * The authentication is done via redux-saga
     *
     * When we will do the authentication via react-query, we will need to handle
     * the headers `authorization` (token), `refresh` and `keyId`
     *
     * see `packages/core/src/rest/dataSimpleRest/index.ts`
     */
    const iv = String(responseHeaders.get('iv'));
    const contentType = String(responseHeaders.get('content-type'));

    const originalData = res.data;
    if (res.data && iv && aesKey) {
      const decipheredData = await EncryptionService.decodeCipher(
        !contentType?.includes('application/json')
          ? encryptionInfos?.aesFileEncryptionKey || ''
          : aesKey || '',
        !contentType?.includes('application/json')
          ? encryptionInfos?.ivFileEncryptionKey || ''
          : iv || '',
        originalData,
        contentType,
      );
      res.data = decipheredData || originalData;
    }
    return res;
  });
};
