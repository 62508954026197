import * as React from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useCurrentUser } from '@docavenue/components';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import dynamic from 'next/dynamic';
import {
  Box,
  Button,
  NoSsr,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  IconDrug,
  IconPersonRoundFilled,
  VectorLogoBCB,
  VectorLogoMaiiaMonochrome,
} from '@maiia/design-system';
import Link from '../../atoms/Link/Link';
import Menu from '../../molecules/Menu/Menu';
import Avatar from '../../atoms/Avatar/Avatar';
import {
  isDemoAccountSelector,
  temporaryAppointmentSelector,
} from '../../../src/selectors';
import { useTranslation } from '@/src/i18n';

import { dayjsWithTimezone } from '@/src/utils';
import { APP_ROUTES, DEFAULT_TIMEZONE } from '@/src/constants';
import { temporaryAppointmentsActions } from '../../../src/actions';
import { getCardFullName, getAvatar, getSubstitute } from '../../utils';
import HomeBanner from './HomeBanner';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import useBranding from '@/src/hooks/branding/useBranding';
import BCBButton from '@/components/organisms/Header/BCBButton';
import { isValidTemporaryAppointment } from '@/components/utils/appointment';
import { screebEventTrack } from '@/src/screeb';

type Props = {
  className?: string;
  variant?: string;
};

const SwitchLanguage = dynamic(() => import('./SwitchLanguage'), {
  ssr: false,
});

const SearchBar = dynamic(() => import('../../molecules/SearchBar/SearchBar'));

const { HOME, LOGIN, APPOINTMENT_CONFIRMATION, SPECIALITY } = APP_ROUTES;

const pagesNotShowBannerContinueScheduleAppointment = [
  APPOINTMENT_CONFIRMATION,
  SPECIALITY.LOCALITY_SLUG,
  LOGIN,
];

/* const routeEnabledCovidBanner = isLoggedIn => isLoggedIn ? [NO_RDV_SEARCH_SLUG] : [HOME, NO_RDV_SEARCH_SLUG]; // Hide covid banner on homepage if user is connected */
const routeEnabledCovidBanner: (...args: any) => string[] = () => []; // Disable completely covid banner for now
const enableBCBCtaRoutes = (isLoggedIn: boolean) => (!isLoggedIn ? [HOME] : []); // Display BCB CTA on homepage if user is not connected
const disabledLogoRoutes = [SPECIALITY.LOCALITY_SLUG]; // Special request for vaccination campaign
const publicSanteHosts = ['sante', 'csb']; // csb = codesandbox for testing only

const TemporaryAppointmentBanner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const temporaryAppointment = useSelector(temporaryAppointmentSelector);
  const { substitute } = getSubstitute(temporaryAppointment);
  const fullName = getCardFullName(t, {
    ...temporaryAppointment?.cardHcd,
    substituteData: substitute,
  });
  const dayjsTZ = dayjsWithTimezone(
    temporaryAppointment?.cardHcd?.settings?.timeZone || DEFAULT_TIMEZONE,
  );
  const temporaryAppointmentStartDate = dayjsTZ(
    temporaryAppointment?.startDate,
  ).format('dddd D MMMM [à] HH[h]mm');
  const avatar = getAvatar(temporaryAppointment);
  const cancelTemporaryAppointment = React.useCallback(() => {
    dispatch(temporaryAppointmentsActions.resetItem());
  }, []);

  return (
    <div className="bandeau-action">
      <Avatar source={avatar} size="small" />
      <p className="killonmobile">
        {t('continue_schedule_appointment', {
          motif: temporaryAppointment?.consultationReason?.name,
          date: temporaryAppointmentStartDate,
          // todo(raph): change practicien to entity (poeditor & here)(hotfix for synchro)
          practicien: fullName,
        })}
      </p>
      <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: 'column', sm: 'row' }}>
        {/* Spécific mobile  */}
        <p className="killon1023">
          {temporaryAppointment?.consultationReason?.name}
          <br />
          <span>{temporaryAppointmentStartDate}</span>
        </p>
        <Link href={APPOINTMENT_CONFIRMATION} passHref>
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            variant="contained"
            color="secondary"
          >
            {t('finalize_rdv')}
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          onClick={cancelTemporaryAppointment}
        >
          {t('action_cancel')}
        </Button>
      </Stack>
    </div>
  );
};

const Header = ({ className, variant }: Props) => {
  const { t } = useTranslation();

  const router = useRouter();
  const currentUser = useCurrentUser();
  const isPatientDemo = useSelector(isDemoAccountSelector);
  const { data: branding, isBrandedSubdomain } = useBranding();
  const temporaryAppointment = useSelector(temporaryAppointmentSelector);
  const showCovidBanner = React.useMemo(
    () =>
      routeEnabledCovidBanner(!isEmpty(currentUser)).includes(router.pathname),
    [router.pathname, currentUser],
  );
  const showBCBCta = React.useMemo(
    () => enableBCBCtaRoutes(!isEmpty(currentUser)).includes(router.pathname),
    [router.pathname, currentUser],
  );
  const isMedicationDetailRoute = React.useMemo(
    () => router.route.startsWith(APP_ROUTES.MEDICAMENTS.BASE_URL),
    [router.route],
  );

  const [showLogo, setShowLogo] = React.useState(true);
  const showBannerContinueScheduleAppointment = React.useMemo(
    () =>
      !pagesNotShowBannerContinueScheduleAppointment.includes(router.pathname),
    [router.pathname],
  );
  const patient = useCurrentUser()?.userPatientInformation?.userPatientProfiles?.find(
    p => p.isDefault,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    // Special request to hide Maiia logo when the page is hcd and the referrer is sante.fr
    if (document.referrer && disabledLogoRoutes.includes(router.pathname)) {
      const url = new URL(document.referrer);
      for (const publicSanteHost of publicSanteHosts) {
        if (url.hostname.includes(publicSanteHost)) {
          setShowLogo(false);
        }
      }
    } else {
      setShowLogo(true);
    }
  }, [router.pathname]);

  return (
    <>
      {showCovidBanner && (
        <div className="bandeau-alert">
          <figure>
            <img
              src="/static/images/logo-rf-big.png"
              height="28"
              width="auto"
              alt="République Française"
            />
          </figure>
          <p>{t('discover-government-infos-about-covid')}</p>
        </div>
      )}
      {showBannerContinueScheduleAppointment &&
        isValidTemporaryAppointment(temporaryAppointment) &&
        currentUser && <TemporaryAppointmentBanner />}
      {router.pathname === HOME && <HomeBanner />}
      <div
        data-testid="main-header"
        className={clsx(
          'header',
          className && `header--${className}`,
          variant && `header--${variant}`,
          !isEmpty(currentUser) && 'connected',
          showBCBCta && `with-bcb-cta`,
        )}
      >
        <div className="header__menu-toggle-logo">
          {!isEmpty(currentUser) && <Menu />}
          {!showBCBCta && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                // If the user is connected, the content is centered
                ...(!isEmpty(currentUser)
                  ? {
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%) scale(1.2)',
                    }
                  : {}),
              }}
            >
              {/* branding name or Maiia Logo + Demo tag */}
              <Link href={HOME} passHref>
                <a
                  className="header__logo"
                  style={{
                    display: showLogo ? 'block' : 'none',
                  }}
                  data-cy="header-logo"
                  title={t('back-to-maiia-home')}
                  aria-label={t('back-to-maiia-home')}
                >
                  <div className="brandname">
                    {isBrandedSubdomain ? (
                      <span className="brandname--pharmacy">
                        {branding?.name}
                      </span>
                    ) : (
                      <>
                        <VectorLogoMaiiaMonochrome
                          className="logo--small"
                          role="img"
                          alt="Maiia"
                          aria-label="Maiia"
                        />
                        {isPatientDemo && <span className="is-demo" />}
                      </>
                    )}
                  </div>
                </a>
              </Link>
              {/* BCB logo if we are on medication detail page */}
              {isMedicationDetailRoute && (
                <>
                  <Typography
                    component="span"
                    sx={{
                      margin: `0 ${theme.spacing(1)}`,
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    {t('amp')}
                  </Typography>
                  <Link href={APP_ROUTES.MEDICAMENTS.BASE_URL} passHref>
                    <Typography
                      component="a"
                      sx={{
                        color: theme.palette.primary.contrastText,
                      }}
                      title={t('seo-title_directory_medicaments')}
                      aria-label={t('seo-title_directory_medicaments')}
                    >
                      <VectorLogoBCB
                        className="logo--small"
                        role="img"
                        alt={t('drugs-menu')}
                      />
                    </Typography>
                  </Link>
                </>
              )}
            </Box>
          )}
          {showBCBCta && (
            <div className="header__cta-bcb killonmobile">
              <Link href={APP_ROUTES.MEDICAMENTS.BASE_URL} passHref>
                <BCBButton
                  variant="contained"
                  startIcon={<IconDrug aria-hidden="true" />}
                  // data-testid="bcb-btn"
                  onClick={() => {
                    analyticsEvent({
                      category: 'Home',
                      action: 'BCB',
                    });
                    screebEventTrack('BCB CTA');
                  }}
                  data-testid="infos-drug-button"
                >
                  {t('info-drugs-bcb')}
                </BCBButton>
              </Link>
            </div>
          )}
        </div>

        {variant === 'with-searchbar' && <SearchBar allowClear />}
        <div className="header-links">
          <SwitchLanguage />

          {isEmpty(currentUser) ? (
            <NoSsr>
              <a
                className="btn cta-professional"
                data-cy={isMobile ? 'btn-header-help' : 'btn-header-pro'}
                href={
                  isMobile
                    ? 'https://maiia-guide.zendesk.com/hc/fr'
                    : 'https://suite.maiia.com/'
                }
              >
                <span>
                  {t(isMobile ? 'need-help' : 'are_you_professional')}
                </span>
              </a>
              <Link href={LOGIN} passHref>
                <a
                  href="#"
                  className="login__link not-login"
                  data-cy="header-login"
                  title={t('login_register')}
                  aria-label={t('login_register')}
                >
                  <IconPersonRoundFilled
                    className="btn header__avatar"
                    aria-hidden="true"
                  />
                  <span>{t('login_register')}</span>
                </a>
              </Link>
            </NoSsr>
          ) : (
            <>
              <Link href={APP_ROUTES.ACCOUNT.PROFILE} passHref>
                <a className="login__link" data-cy="header-account">
                  <div className="btn patient-avatar patient-card">
                    <span className="initial">
                      {patient?.firstName?.charAt(0)?.toUpperCase() ?? '!'}
                    </span>
                    <span className="fullname">
                      {`${patient?.firstName ?? ''} ${patient?.lastName ??
                        t('finalize_my_registration')}`}
                    </span>
                  </div>
                </a>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
