import { styled, css } from '@mui/material/styles';
import { Button } from '@mui/material';

const BCBButton = styled(Button)(
  ({ theme }) => css`
    white-space: nowrap;
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.white};

    &:hover {
      box-shadow: 0 0 6px 0 ${theme.palette.common.white};
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.common.white};
    }
  `,
);

export default BCBButton;
