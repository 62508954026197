import * as React from 'react';
import { styled, css } from '@mui/material/styles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  NoSsr,
  Stack,
  Box,
} from '@mui/material';
import {
  IconChevronDown,
  VectorLogoMaiiaMonochrome,
} from '@maiia/design-system';
import NextLink from 'next/link';
import { aboutMaiiaFooter } from './footerMenus';
import { Trans, useTranslation } from '@/src/i18n';
import MenuSocial from './MenuSocial';
import useResponsive from '@/src/hooks/useResponsive';

/* JS styling */

const FooterAccordion = styled(Accordion)(
  ({ theme }) => css`
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid ${theme.palette.common.white};
    border-bottom: 1px solid ${theme.palette.common.white};

    &:first-of-type {
      border-radius: 0;
    }

    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;

      .MuiAccordionSummary-content {
        margin-top: ${theme.spacing(3)};
        margin-bottom: ${theme.spacing(3)};

        h2 {
          margin-bottom: 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: auto;
          height: ${theme.spacing(3)};
          fill: ${theme.palette.common.white};
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 0 0 ${theme.spacing(3)} 0;

      ul {
        li {
          color: ${theme.palette.common.white};
        }
      }
    }
    &.Mui-expanded {
      margin: 0;
    }
  `,
);

const Logo = styled(Box)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    margin-bottom: 24px;
    svg {
      width: auto;
      height: 42px;
    }
  `,
);

/* Component */

const MenuAboutMaiia = () => {
  const { t } = useTranslation();
  const footerMenusTranslated = aboutMaiiaFooter(t);
  const { isMobile } = useResponsive();

  const accordionTitle = (
    <Typography variant="h3" component="h2">
      {t('footer_about_maiia')}
    </Typography>
  );
  const accordionContent = (
    <Box textAlign={isMobile ? 'center' : 'left'} width="100%">
      <Logo>
        <VectorLogoMaiiaMonochrome role="img" alt="Maiia" aria-label="Maiia" />
      </Logo>
      <Typography
        variant="body2"
        color="primary.contrastText"
        sx={{
          paddingBottom: 3,
          margin: isMobile ? '0 auto' : '0',
        }}
      >
        <Trans
          i18nKey="footer_about_maiia_subtitle_with_br"
          components={[<br key="br" />]}
        />
      </Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={3}>
        {footerMenusTranslated.map(item => (
          <Typography
            key={item.id}
            sx={{ fontWeight: '600' }}
            color="primary.contrastText"
          >
            <NextLink href={item.url} key={item.id}>
              <a data-testid={item?.id} onClick={item?.onClick}>
                {item.title}
              </a>
            </NextLink>
          </Typography>
        ))}
      </Stack>

      {isMobile && <MenuSocial />}
    </Box>
  );

  return (
    <NoSsr>
      {isMobile ? (
        <FooterAccordion>
          <AccordionSummary expandIcon={<IconChevronDown />}>
            {accordionTitle}
          </AccordionSummary>
          <AccordionDetails>{accordionContent}</AccordionDetails>
        </FooterAccordion>
      ) : (
        <>{accordionContent}</>
      )}
    </NoSsr>
  );
};

export default MenuAboutMaiia;
