import * as React from 'react';
import clsx from 'clsx';
import { devices } from '@maiia/design-system';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useCurrentUser } from '@docavenue/components';
import Link from '../../atoms/Link/Link';
import { Trans, useTranslation } from '@/src/i18n';
import config from '../../../src/config';
import MenuAboutMaiia from '@/components/organisms/Footer/MenuAboutMaiia';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import MenuApplications from './MenuApplications';
import MenuSocial from './MenuSocial';
import { APP_ROUTES } from '@/src/constants';
import useResponsive from '@/src/hooks/useResponsive';
import DynamicFooter from './DynamicFooter/DynamicFooter';
import SpecialitiesAndMenuInformations from './SpecialitiesAndMenuInformations';

/* JS styling */

const FooterWrapper = styled('div')<{ dynamic?: boolean }>(
  ({ theme, dynamic = false }) => css`
    color: ${dynamic
      ? theme.palette.infoBlue.light
      : theme.palette.common.white};
    padding: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)};
    background: ${theme.palette.background[
      dynamic ? 'infoLightBlue' : 'gradientPrimary'
    ]};

    @media ${devices.desktop} {
      padding-top: ${theme.spacing(3)};
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
    }

    h4,
    h2,
    h3 {
      color: ${dynamic
        ? theme.palette.infoBlue.light
        : theme.palette.common.white};
      font-weight: 600;
    }

    .with-disc {
      li {
        list-style-type: disc;
        margin-left: ${theme.spacing(1.5)};
      }
    }

    &.dynamic {
      padding-bottom: 0;

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(3, 2)};
      }

      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(3, 8)};
      }
    }
  `,
);

const SectionsDivider = styled(Divider, {
  shouldForwardProp: () => true,
})(
  ({ theme }) => css`
    border-color: ${theme.palette.common.white};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
);

const SupportWrapper = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    background: ${theme.palette.primary.contrastText};

    @media ${devices.desktop} {
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
    }
  `,
);

/* Component */

type Props = {
  className?: string;
  pageProps?: React.ComponentProps<'div'>;
};

const Footer = ({ className, pageProps }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const isHomepage = router.pathname === APP_ROUTES.HOME;
  const isFooterSupportSeparator = !!currentUser && !isMobile;

  return (
    <>
      <SupportWrapper
        textAlign="center"
        sx={theme => ({
          background: theme.palette.background.gradientPrimary,
          ...(isFooterSupportSeparator && {
            paddingBottom: 0,
          }),
        })}
        className="footer-support"
      >
        <Typography variant="h3" component="h2" color="primary.contrastText">
          {t('need-help')}
        </Typography>
        <Typography fontSize={16} color="primary.contrastText">
          <Trans
            i18nKey="contact-our-support-center-or-contact-us-with-underline"
            components={{
              underline: (
                <Typography
                  component="span"
                  fontWeight={500}
                  fontSize={16}
                  sx={{ textDecoration: 'underline' }}
                />
              ),
            }}
          />
        </Typography>

        <Link href="https://maiia-guide.zendesk.com/hc/fr" passHref>
          <Button
            sx={{ mt: 3 }}
            color="secondary"
            variant="contained"
            onClick={() =>
              analyticsEvent({
                category: 'Footer',
                action: 'HelpFooter',
              })
            }
          >
            {t('help-menu')}
          </Button>
        </Link>

        {isFooterSupportSeparator && (
          <Divider
            sx={theme => ({
              mt: 4,
              borderColor: theme.palette.primary.contrastText,
            })}
          />
        )}
      </SupportWrapper>

      {!isHomepage && !currentUser && (
        <FooterWrapper
          data-testid="dynamic-footer"
          className={clsx(
            'footer dynamic',
            className && `footer--${className}`,
          )}
          dynamic
        >
          <DynamicFooter pageProps={pageProps} />
        </FooterWrapper>
      )}

      <FooterWrapper
        data-testid="main-footer"
        className={clsx('footer', className && `footer--${className}`)}
      >
        {isHomepage && !currentUser && (
          <>
            {!isMobile && <SectionsDivider />}

            <SpecialitiesAndMenuInformations />

            {!isMobile && <SectionsDivider />}
          </>
        )}

        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <MenuAboutMaiia />

          <MenuApplications />
        </Stack>

        <SectionsDivider />

        <Stack direction="row" justifyContent="space-between">
          <Box
            textAlign={isMobile ? 'center' : 'left'}
            width={isMobile ? '100%' : '40%'}
          >
            <Typography sx={{ fontWeight: '600' }}>
              {t('copyright_for_footer', {
                year: new Date().getFullYear(),
              })}
            </Typography>

            {config.get('RELEASE_VERSION') && (
              <p className="percy-hidden">
                {t(`footer_version_number`, {
                  version: config.get('RELEASE_VERSION'),
                  buildNumber: config.get('BUILD_NUMBER', 'dev'),
                })}
              </p>
            )}

            <Typography pt={1.5} variant="body2">
              {t('footer__priorities_practitioners')}
            </Typography>
          </Box>

          {!isMobile && <MenuSocial />}
        </Stack>
      </FooterWrapper>
    </>
  );
};

export default Footer;
