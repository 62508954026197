import { useMediaQuery, Theme } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';

const SSR_MOBILE_FIRST = false;

const useResponsive = () => {
  const [isClientSide, setIsClientSide] = useState(false);
  // eslint-disable-next-line no-unneeded-ternary
  const defaultIsMobile = SSR_MOBILE_FIRST ? true : false;
  const isMobileQueryResult = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('sm'),
  );
  const isMobile = isClientSide ? isMobileQueryResult : defaultIsMobile;

  //  to fix the Warning useLayoutEffect does nothing on the server
  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    setIsClientSide(true);
  }, []);

  return {
    isMobile,
    isDesktop: !isMobile,
  };
};

export default useResponsive;
